@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background: var(--background);
}

:root {
  --background: #fff;
  --background-secondary: #f3f3f3;
  --background-card: var(--background);
  --text-primary: #333;
  --text-secondary: #666;
  --text-light: #b3b3b3;
  --border: #ebebeb;
  --hover: #0000000a;

  --blue: #5297ff;
  --purple: #4437ca;
  --green: #46a846;
  --red: #ff4d4d;
  --yellow: #ffcc00;
  --timeline-line: #2f3134;
  --timeline-clip: #166b4c;
  --highlight: #05aaff;

  --tw-shadow: 0px 0px 0px 1px rgba(10, 10, 46, 0.16) inset,
    0px 1px 1px rgba(10, 10, 11, 0.06);
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color),
    0px 1px 1px var(--tw-shadow-color);
  --frosted: rgba(255, 255, 255, 0.2);
}

:root[data-theme="dark"] {
  --background: #171717;
  --background-secondary: #1d1d1d;
  --background-card: var(--background-secondary);
  --text-primary: #ebebeb;
  --text-secondary: #b3b3b3;
  --text-tertiary: #666666;
  --text-light: #666666;
  --border: #292929;
  --hover: #ffffff0f;

  --blue: #5297ff;
  --purple: #4437ca;
  --green: #46a846;
  --red: #ff4d4d;
  --yellow: #ffcc00;
  --timeline-line: #2f3134;
  --timeline-clip: #166b4c;
  --highlight: #05aaff;

  --tw-shadow: 0px 0px 0px 1px rgba(235, 235, 235, 0.16) inset,
    0px 1px 1px rgba(235, 235, 235, 0.06);
  --tw-shadow-colored: inset 0px 0px 0px 1px var(--tw-shadow-color),
    0px 1px 1px var(--tw-shadow-color);
  --frosted: rgba(0, 0, 0, 0.2);
}

html,
body,
#root {
  height: 100%;
  margin: 0px;
  font-family: Inter;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

/* This styles the scrollbar track (the part the thumb slides within) */
::-webkit-scrollbar-track {
  background: none;
}

/* This styles the scrollbar thumb (the part that is draggable) */
::-webkit-scrollbar-thumb {
  background-color: var(--border); /* Dark grey thumb */
  border-radius: 4px;
}

/* This styles the scrollbar itself (including the thumb, track, and buttons) */
::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

/* Optional: styling for the scrollbar buttons (arrows at the ends of the scrollbar) */
::-webkit-scrollbar-button {
  display: none; /* Disables the buttons */
}
